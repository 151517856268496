import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload, {
  UploadedFileList,
} from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { getNestedValue } from "../../../utils/getNestedValue";
import StyledLegend from "../../../components/Legend/Legend";
import { Asterisk } from "../../../components/FormFields/Field";
import { useFormContext } from "../../../components/FormContext/FormContext";
import { useNavigate } from "react-router-dom";
import { useAdminSettings } from "../../../components/AdminSettingsProvider/AdminSettingsProvider";

const requiredWarning = "This field is required.";

export const schema = {
  values: {
    deniedCoverage: "",
    notEligibleForOtherInsurance: "",
    exceedsQualifyingRate: "",
  },
  validation: Yup.object().shape({
    deniedCoverage: Yup.string().required(requiredWarning),
    notEligibleForOtherInsurance: Yup.string().required(requiredWarning),
    exceedsQualifyingRate: Yup.string().required(requiredWarning),
  }),
};

const ProofOfEligibility = () => {
  const { formId } = useFormContext(); // Get formId from context
  const navigate = useNavigate();
  const { values } = useFormikContext();
  const { settings } = useAdminSettings();

  return (
    <>
      <FormGrid className="flex-col">
        <Radio
          className="!max-w-[700px]"
          name="deniedCoverage"
          label="I have been denied health insurance coverage."
          options={[{ label: "Yes" }, { label: "No" }]}
          required
        />
        {/* <Button
					type="button"
					onClick={() =>
						navigate("/lipp-form/", {
							state: {
								basicApp: values,
							},
						})
					}
				>
					testing lipp
				</Button> */}
        <Radio
          className="!max-w-[700px]"
          name="notEligibleForOtherInsurance"
          label="I am not eligible for any other form of health insurance or Medicaid."
          options={[
            { label: "Yes, I am not eligible" },
            { label: "No, I am eligible" },
          ]}
          required
        />
        <div className="!max-w-[700px]">
          <StyledLegend>
            <p>
              The premium rate for my current or applied-for{" "}
              <span className="underline">individual</span> comprehensive health
              insurance coverage exceeds the “Qualifying Rate” of NMMIP's
              deductible plan nearest to my age, tobacco status, and
              geographical area. <Asterisk />
            </p>
          </StyledLegend>
          <Button
            type="button"
            href={
              settings?.qualifying_rate_chart_link ||
              "https://nmmip.org/rate-tables/"
            }
          >
            View Qualifying Rate Chart
          </Button>
        </div>

        <Radio
          className="!max-w-[700px]"
          name="exceedsQualifyingRate"
          label="The premium rate for my current or applied-for individual comprehensive health insurance coverage exceeds the “Qualifying Rate” of NMMIP's deductible plan nearest to my age, tobacco status, and geographical area."
          options={[{ label: "Yes" }, { label: "No" }]}
          required
          showLegend={false}
        />
      </FormGrid>

      <Modal
        modalLabel="Proof of Eligibility Documentation"
        buttonLabel="Upload Documentation"
      >
        <NoticeBanner bannerstyle={ColorStyle.Primary}>
          Upload either a Rejection Notice or Broker Attestation: If you were
          denied insurance coverage or are ineligible for any other form of
          major medical health insurance.
        </NoticeBanner>
        <NoticeBanner bannerstyle={ColorStyle.Primary}>
          OR upload a Quote: If you received a quote for comparable insurance
          from an insurance carrier or NMHIX that exceeds the Qualifying Rate of
          NMMIP.
        </NoticeBanner>
        <NoticeBanner bannerstyle={ColorStyle.Secondary}>
          If you don't have the documentation electronically, please mail it to
          NMMIP upon completing this application.
        </NoticeBanner>
        <FileUpload
          name="documentation.proofOfEligibility"
          label="Upload Documentation"
          documentationType="proofOfEligibility"
        />
      </Modal>

      <div className="max-w-[500px]">
        <UploadedFileList
          formId={formId}
          fieldName={"documentation.proofOfEligibility"}
          documentationType="proofOfEligibility"
        />
      </div>
    </>
  );
};

export default ProofOfEligibility;
