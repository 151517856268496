import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload, {
  FileObj,
  UploadedFile,
  UploadedFileList,
} from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { normalizeDate } from "../../../utils/normalizeDate";
import { PaymentTypes } from "../FormSchema/formInitialValues";
import { FaCloudUploadAlt, FaTimesCircle } from "react-icons/fa";
import { useFormContext } from "../../../components/FormContext/FormContext";
import { useAdminSettings } from "../../../components/AdminSettingsProvider/AdminSettingsProvider";

const requiredWarning = "This field is required.";

export const schema = {
  values: {
    // documentation: {
    // 	poa: [],
    // 	proofOfResidency: [],
    // 	proofOfEligibility: [],
    // 	priorCoverage: [],
    // 	voidedCheck: [],
    // },
  },
  validation: Yup.object().shape({}),
};

const Documentation = () => {
  const { errors, values, setFieldValue } = useFormikContext<any>();
  const { formId } = useFormContext(); // Get formId from context
  const isPoa =
    values["applicant"]["preferredAdditionalContact"] === "Power of Attorney";
  const isACH =
    values["paymentType"] === PaymentTypes[1].label ||
    values["paymentType"] === PaymentTypes[2].label;
  const { settings } = useAdminSettings();
  const handleDelete = (fieldName: string, id: string) => {
    const arr = getNestedValue(values, fieldName);

    if (arr && arr.length > 0) {
      const fileToRemoveIndex = arr.findIndex(
        (file: FileObj) => file.id === id
      );
      if (fileToRemoveIndex !== -1) {
        setFieldValue(fieldName, arr.toSpliced(fileToRemoveIndex, 1));
      }
    }
  };

  return (
    <>
      <div className="flex flex-col gap-[30px]">
        <p>
          To apply for New Mexico Medical Insurance Pool (NMMIP) coverage you
          will need to submit the following supporting documentation.
          <br /> <br />
          If you don't have the documentation electronically, please mail it to
          NMMIP upon completing this application.
        </p>
        <p className="leading-[1.5rem]">
          <strong>Mail Supporting Documentation to:</strong> <br />
          <span
            dangerouslySetInnerHTML={{
              __html:
                settings?.supporting_documentation_address ||
                `New Mexico Medical Insurance Pool (NMMIP) <br />
								PO Box 780548 <br />
								San Antonio, TX 78278`,
            }}
          ></span>
        </p>

        {isPoa && (
          <div>
            <h2 className="mb-[1rem]">Power of Attorney Documentation</h2>
            <div className="max-w-[500px] mb-[1rem]">
              <UploadedFileList
                formId={formId}
                fieldName={"documentation.poa"}
                documentationType={"poa"}
              />
            </div>
            <Modal
              modalLabel="Power of Attorney Documentation"
              buttonLabel="Upload Documentation"
            >
              <NoticeBanner bannerstyle={ColorStyle.Primary}>
                You have indicated that you will be using a Power of Attorney
                (POA). Please provide Power of Attorney documentation.
              </NoticeBanner>
              <NoticeBanner bannerstyle={ColorStyle.Secondary}>
                If you don't have the documentation electronically, please mail
                it to NMMIP upon completing this application.
              </NoticeBanner>
              <FileUpload
                name="documentation.poa"
                label="Upload Documentation"
                documentationType="poa"
              />
            </Modal>
          </div>
        )}

        <div>
          <h2 className="mb-[1rem]">Proof of Residency Documentation</h2>
          <div className="max-w-[500px] mb-[1rem]">
            <UploadedFileList
              formId={formId}
              fieldName={"documentation.proofOfResidency"}
              documentationType="proofOfResidency"
            />
          </div>
          <Modal
            modalLabel="Proof of Residency Documentation"
            buttonLabel="Upload Documentation"
          >
            <NoticeBanner bannerstyle={ColorStyle.Primary}>
              You have indicated that you are a resident of the State of New
              Mexico. Please provide Proof of Residency Documentation (lease
              agreement, mortgage statement, utility bill, or other document).
            </NoticeBanner>
            <NoticeBanner bannerstyle={ColorStyle.Secondary}>
              If you don't have the documentation electronically, please mail it
              to NMMIP upon completing this application.
            </NoticeBanner>
            <FileUpload
              name="documentation.proofOfResidency"
              label="Upload Documentation"
              documentationType="proofOfResidency"
            />
          </Modal>
        </div>
        <div>
          <h2 className="mb-[1rem]">Proof of Eligibility Documentation</h2>
          <div className="max-w-[500px] mb-[1rem]">
            <UploadedFileList
              formId={formId}
              fieldName={"documentation.proofOfEligibility"}
              documentationType="proofOfEligibility"
            />
          </div>
          <Modal
            modalLabel="Proof of Eligibility Documentation"
            buttonLabel="Upload Documentation"
          >
            <NoticeBanner bannerstyle={ColorStyle.Primary}>
              Upload either a Rejection Notice or Broker Attestation: If you
              were denied insurance coverage or are ineligible for any other
              form of major medical health insurance.
            </NoticeBanner>
            <NoticeBanner bannerstyle={ColorStyle.Primary}>
              OR upload a Quote: If you received a quote for comparable
              insurance from an insurance carrier or NMHIX that exceeds the
              Qualifying Rate of NMMIP.
            </NoticeBanner>
            <NoticeBanner bannerstyle={ColorStyle.Secondary}>
              If you don't have the documentation electronically, please mail it
              to NMMIP upon completing this application.
            </NoticeBanner>
            <FileUpload
              name="documentation.proofOfEligibility"
              label="Upload Documentation"
              documentationType="proofOfEligibility"
            />
          </Modal>
        </div>

        <div>
          <h2 className="mb-[1rem]">Documentation of Prior Coverage</h2>
          <div className="max-w-[500px] mb-[1rem]">
            <UploadedFileList
              formId={formId}
              fieldName={"documentation.priorCoverage"}
              documentationType="priorCoverage"
            />
          </div>
          <Modal
            modalLabel="Documentation of Prior Coverage"
            buttonLabel="Upload Documentation"
          >
            <NoticeBanner bannerstyle={ColorStyle.Primary}>
              Upload Documentation of Prior Coverage: Proof of coverage from
              your previous insurance carrier(s) such as Individual, Group,
              COBRA, Medicaid, SCI, etc. Ensure documentation shows 18 months of
              continuous coverage.
            </NoticeBanner>
            <NoticeBanner bannerstyle={ColorStyle.Secondary}>
              If you don't have the documentation electronically, please mail it
              to NMMIP upon completing this application.
            </NoticeBanner>
            <FileUpload
              name="documentation.priorCoverage"
              label="Upload Documentation"
              documentationType="priorCoverage"
            />
          </Modal>
        </div>

        {isACH && (
          <div>
            <h2 className="mb-[1rem]">Voided Check</h2>
            <div className="max-w-[500px] mb-[1rem]">
              <UploadedFileList
                formId={formId}
                fieldName={"documentation.voidedCheck"}
                documentationType="voidedCheck"
              />
            </div>
            <Modal modalLabel="Voided Check" buttonLabel="Upload Voided Check">
              <NoticeBanner bannerstyle={ColorStyle.Primary}>
                You'll need to upload a voided check. A deposit ticket will not
                be accepted
              </NoticeBanner>
              <NoticeBanner bannerstyle={ColorStyle.Secondary}>
                If you don't have the documentation electronically, please mail
                it to NMMIP upon completing this application.
              </NoticeBanner>
              <FileUpload
                name="documentation.voidedCheck"
                label="Upload Files"
                documentationType="voidedCheck"
              />
            </Modal>
          </div>
        )}
      </div>
    </>
  );
};

export default Documentation;
