import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import TextField from "../FormFields/Textfield";
import Button from "../Button/Button";
import AdminDashboard from "../AdminSettingsProvider/AdminDashboard";
import logo from "../../assets/NMMIP_logo.webp";

const AdminVerificationForm: React.FC = () => {
	const [step, setStep] = useState<"enterEmail" | "verifyCode" | "dashboard">(
		"enterEmail"
	);
	const [email, setEmail] = useState<string | null>(null);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const emailValidationSchema = Yup.object({
		email: Yup.string().email("Invalid email").required("Email is required"),
	});

	const codeValidationSchema = Yup.object({
		code: Yup.string()
			.required("Verification code is required")
			.length(6, "Code must be 6 digits"),
	});

	const handleStartVerification = async (values: { email: string }) => {
		setErrorMessage(null);
		try {
			await axios.post("/api/twilio/admin-start-verification", {
				email: values.email,
			});
			setEmail(values.email);
			setStep("verifyCode");
		} catch (error) {
			setErrorMessage(
				axios.isAxiosError(error) && error.response
					? error.response.data.error || "An error occurred"
					: "Failed to start verification. Please try again."
			);
		}
	};

	const handleCheckVerification = async (values: { code: string }) => {
		setErrorMessage(null);
		try {
			const response = await axios.post(
				"/api/twilio/admin-check-verification",
				{ email, code: values.code }
			);
			if (response.data.success) {
				localStorage.setItem("adminToken", response.data.token);
				setStep("dashboard"); // Transition to the dashboard
			} else {
				setErrorMessage("Invalid code.");
			}
		} catch (error) {
			setErrorMessage("Verification failed.");
		}
	};

	return (
		<div
			className={`flex flex-col gap-[1rem] mx-auto bg-white p-4 sm:p-8 rounded-lg shadow-lg ${
				step === "dashboard" ? "w-full max-w-[85vw]" : "max-w-md"
			}`}
		>
			{step !== "dashboard" && (
				<>
					<img
						src={logo}
						alt="NMMIP Logo"
						className="w-1/2 mx-auto cursor-pointer"
						onClick={() => (window.location.href = "https://nmmip.org/")}
					/>
					<h1 className="mx-auto text-center text-[24px]">Admin Dashboard</h1>
					<p className="text-center">
						Enter your email to receive a one-time password (OTP) for
						verification and access to the admin dashboard.
					</p>
				</>
			)}

			{step === "enterEmail" && (
				<Formik
					initialValues={{ email: "" }}
					validationSchema={emailValidationSchema}
					onSubmit={handleStartVerification}
				>
					{({ isSubmitting }) => (
						<Form>
							<TextField
								name="email"
								label="Email Address"
								type="text"
								required
							/>
							{errorMessage && (
								<p className="text-red-600 text-sm mt-2">{errorMessage}</p>
							)}
							<Button
								type="submit"
								disabled={isSubmitting}
								className="mx-auto !w-full justify-center mt-[1rem]"
							>
								Send Verification Code
							</Button>
						</Form>
					)}
				</Formik>
			)}

			{step === "verifyCode" && (
				<Formik
					initialValues={{ code: "" }}
					validationSchema={codeValidationSchema}
					onSubmit={handleCheckVerification}
				>
					{({ isSubmitting }) => (
						<Form>
							<TextField
								name="code"
								type="text"
								label="Verification Code"
								required
							/>
							{errorMessage && (
								<p className="text-red-600 text-sm mt-2">{errorMessage}</p>
							)}
							<Button
								type="submit"
								disabled={isSubmitting}
								className="mx-auto !w-full justify-center mt-[1rem]"
							>
								Verify Code
							</Button>
						</Form>
					)}
				</Formik>
			)}

			{step === "dashboard" && <AdminDashboard />}
		</div>
	);
};

export default AdminVerificationForm;
