import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";

// Define the type for settings
type AdminSettings = {
  county_options: any[];
  household_size_options: any[];
  poverty_guideline_options: any[];
  deductible_description: string;
  premium_payment_address: string;
  benefits_information_link: string;
  premium_rates_link: string;
  broker_link: string;
  qualifying_rate_chart_link: string;
  lipp_qualification_guidelines: string;
  lipp_rate_tables: string;
  supporting_documentation_address: string;
  affidavit_year_1: string;
  affidavit_year_2: string;
  deductible_amounts: any[];
  medicare_carve_out_plan: string;
};

// Create a context
const AdminSettingsContext = createContext<{
  settings: AdminSettings | null;
  loading: boolean;
}>({
  settings: null,
  loading: true,
});

// Provide context to the app
export const AdminSettingsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [settings, setSettings] = useState<AdminSettings | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get("/api/twilio/admin-settings");
        setSettings(response.data);
        console.log("Settings?: ", response.data);
      } catch (error) {
        console.error("Error fetching admin settings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  return (
    <AdminSettingsContext.Provider value={{ settings, loading }}>
      {children}
    </AdminSettingsContext.Provider>
  );
};

// Hook to use settings in components
export const useAdminSettings = () => useContext(AdminSettingsContext);
