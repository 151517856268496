import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload, {
  UploadedFileList,
} from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { normalizeDate } from "../../../utils/normalizeDate";
import {
  BankAccountTypes,
  DebitWithdrawlDate,
  DeductibleAmounts,
  MonthsOfYear,
  PaymentTypes,
  USStates,
} from "../FormSchema/formInitialValues";
import { useFormContext } from "../../../components/FormContext/FormContext";
import { useAdminSettings } from "../../../components/AdminSettingsProvider/AdminSettingsProvider";

const requiredWarning = "This field is required.";

export const schema = {
  values: {
    paymentType: "",
    paymentAmount: "",
    preAuthorizedPaymentInfo: {
      lastName: "",
      firstName: "",
      withdrawlDate: "",
      accountType: "",
      bank: "",
      city: "",
      state: "",
      zipCode: "",
      bankRoutingNumber: "",
      bankAccountNumber: "",
      signature: "",
      todaysDate: "",
    },
  },
  validation: Yup.object().shape({
    paymentType: Yup.string().required(requiredWarning),
    paymentAmount: Yup.string()
      .test({
        name: "paymentAmount",
        message: requiredWarning,
        test(value, ctx) {
          const { paymentType } = ctx.parent;
          return paymentType === PaymentTypes[0].label
            ? value
              ? true
              : false
            : true;
        },
      })
      .test({
        name: "paymentAmountPositive",
        message: "Payment amount must be greater than 0",
        test(value, ctx) {
          if (value) {
            return Number(value) > 0;
          } else return true;
        },
      }),
    preAuthorizedPaymentInfo: Yup.object()
      .shape({})
      .when("paymentType", ([paymentType], schema) => {
        if (!paymentType || paymentType === "Money Order or Check") {
          return schema.shape({});
        } else {
          return schema.shape({
            lastName: Yup.string().required(requiredWarning),
            firstName: Yup.string().required(requiredWarning),
            withdrawlDate: Yup.string().required(requiredWarning),
            accountType: Yup.string().required(requiredWarning),
            bank: Yup.string().required(requiredWarning),
            city: Yup.string().required(requiredWarning),
            state: Yup.string().required(requiredWarning),
            zipCode: Yup.string().required(requiredWarning),
            bankRoutingNumber: Yup.string()
              .required(requiredWarning)
              .test(
                "len",
                "Routing number must be exactly 9 digits",
                (val) => val.length === 9
              ),
            bankAccountNumber: Yup.string().required(requiredWarning),
            signature: Yup.string().required(requiredWarning),
            todaysDate: Yup.string()
              .required(requiredWarning)
              .test({
                name: "today",
                message: "Must select today's date",
                test(value, ctx) {
                  if (value) {
                    return new Date(value).getDate() === new Date().getDate();
                  } else return true;
                },
              }),
          });
        }
      }),
  }),
};

const PremiumPayment = () => {
  const { values } = useFormikContext<any>();
  const { formId } = useFormContext(); // Get formId from context
  const { settings, loading } = useAdminSettings();
  const paymentType = values["paymentType"];

  return (
    <>
      <p>
        First payment <span className="underline">must</span> accompany the
        application to be considered for coverage.
      </p>
      <div className="flex gap-[1rem] flex-wrap">
        <Button
          type="button"
          href={
            settings?.benefits_information_link ||
            "https://nmmip.org/members/summary-of-benefits/"
          }
        >
          View Benefits Information
        </Button>
        <Button
          type="button"
          href={
            settings?.premium_rates_link || "https://nmmip.org/rate-tables/"
          }
        >
          View Premium Rates Information
        </Button>
      </div>
      <h2>Your selected deductible amount for coverage:</h2>
      <Dropdown
        className="!max-w-[500px]"
        name="deductible"
        label="Your selected deductible amount for coverage:"
        placeholder="Select an Amount"
        options={settings?.deductible_amounts || DeductibleAmounts}
        required
      />
      <h2>Select the method of payment for your initial premium:</h2>
      <Dropdown
        className="!max-w-[400px]"
        name="paymentType"
        label="Select Type"
        options={PaymentTypes}
        required
      />

      {paymentType === PaymentTypes[0].label && (
        <>
          <TextField
            className="!max-w-[400px]"
            type="currency"
            name="paymentAmount"
            label="Enter Payment Amount"
            required
          />
          <p className="leading-[1.5rem]">
            <strong>Mail Premium Payments to:</strong> <br />
            <span
              dangerouslySetInnerHTML={{
                __html:
                  settings?.premium_payment_address ||
                  `New Mexico Medical Insurance Pool (NMMIP) <br />
									P.O. Box 27745 <br />
									Albuquerque, NM 87125-7745`,
              }}
            ></span>
          </p>
        </>
      )}

      {(paymentType === PaymentTypes[1].label ||
        paymentType === PaymentTypes[2].label) && (
        <>
          <h2>Agreement for Preauthorized Payments</h2>

          <p>
            I hereby authorize the New Mexico Medical Insurance Pool (NMMIP) to
            initiate a debit entry from my account and Depository designated
            below. Pursuant to my election, the debit will be drawn on the first
            or fifteenth of the month unless the date falls on a holiday, then
            it will be drawn the next business day.
          </p>
          {paymentType === PaymentTypes[2].label && (
            <p>
              <span className="underline">
                Your account will be drafted for the month in which you are due
              </span>{" "}
              (e.g., the January amount drafted is for your January coverage).
            </p>
          )}

          <FormGrid>
            <TextField
              className="!basis-[500px]"
              type="text"
              name="preAuthorizedPaymentInfo.lastName"
              label="Last Name"
              required
            />
            <TextField
              className="!basis-[500px]"
              type="text"
              name="preAuthorizedPaymentInfo.firstName"
              label="First Name"
              required
            />
            <Dropdown
              className="!basis-[500px]"
              options={DebitWithdrawlDate}
              name="preAuthorizedPaymentInfo.withdrawlDate"
              label="I elect to have funds withdrawn from my account on"
              required
            />
            <Dropdown
              className="!basis-[300px]"
              options={BankAccountTypes}
              name="preAuthorizedPaymentInfo.accountType"
              label="Type of Account"
              required
            />
            <TextField
              className="!basis-[500px]"
              type="text"
              name="preAuthorizedPaymentInfo.bank"
              label="Name of Financial Institution/Bank (Depository)"
              required
            />
            <TextField
              className="!max-w-[300px]"
              type="text"
              name="preAuthorizedPaymentInfo.city"
              label="City"
              required
              autoComplete="off"
            />
            <Dropdown
              className="!xs:max-w-[200px]"
              name="preAuthorizedPaymentInfo.state"
              label="State"
              options={USStates}
              required
            />
            <TextField
              className="!xs:max-w-[200px]"
              type="text"
              name="preAuthorizedPaymentInfo.zipCode"
              label="Zip Code"
              required
              maxLength={5}
              autoComplete="off"
            />
            <TextField
              className="!max-w-[250px]"
              type="text"
              name="preAuthorizedPaymentInfo.bankRoutingNumber"
              label="Routing Number"
              required
              maxLength={9}
            />
            <TextField
              className="!max-w-[250px]"
              type="text"
              name="preAuthorizedPaymentInfo.bankAccountNumber"
              label="Account Number"
              required
            />
          </FormGrid>
          <p>
            This authority is to remain in full force and effect until NMMIP and
            Depository have received written notification from me of this
            agreement's termination in such time and in such manner as to afford
            NMMIP and the Depository reasonable opportunity to act upon the
            request.
          </p>

          <p className="underline">
            Signature must be from a person who has authority to sign on the
            account to be drafted.
          </p>

          <Signature
            name="preAuthorizedPaymentInfo.signature"
            label="Signature of Applicant"
            signatureType="PremiumPayment"
            required
          />

          <DateField
            className="max-w-[300px]"
            name="preAuthorizedPaymentInfo.todaysDate"
            label="Today's Date"
            placeholder="Select Today's Date"
            required
          />

          <Modal modalLabel="Voided Check" buttonLabel="Upload Voided Check">
            <NoticeBanner bannerstyle={ColorStyle.Primary}>
              You'll need to upload a voided check. A deposit ticket will not be
              accepted
            </NoticeBanner>
            <NoticeBanner bannerstyle={ColorStyle.Secondary}>
              If you don't have the documentation electronically, please mail it
              to NMMIP upon completing this application.
            </NoticeBanner>
            <FileUpload
              name="documentation.voidedCheck"
              label="Upload Files"
              documentationType="voidedCheck"
            />
          </Modal>

          <div className="max-w-[500px]">
            <UploadedFileList
              formId={formId}
              fieldName={"documentation.voidedCheck"}
              documentationType="voidedCheck"
            />
          </div>
        </>
      )}
    </>
  );
};

export default PremiumPayment;
