import * as Yup from "yup";
import Radio from "../../../components/FormFields/Radio";
import FormGrid from "../../../components/FormGrid/FormGrid";
import Button from "../../../components/Button/Button";
import { useAdminSettings } from "../../../components/AdminSettingsProvider/AdminSettingsProvider";

export const schema = {
  values: {},
  validation: Yup.object().shape({}),
};

const Intro = () => {
  const { settings } = useAdminSettings();
  return (
    <>
      <p>
        You have indicated that you meet The Low Income Premium Program (LIPP)
        eligibility requirements and will be applying for the LIPP Program. This
        is <strong>NOT</strong> an application for coverage.{" "}
        <strong>
          If you are not already a NMMIP member, please return to the previous
          page and complete an application for coverage first.
        </strong>
      </p>
      <p>
        The Low Income Premium Program (LIPP) is designed to help persons who
        qualify for New Mexico Medical Insurance Pool (NMMIP) coverage by
        offering a reduced premium. Income does not determine NMMIP eligibility.
        If you meet the LIPP eligibility requirements, you must complete and
        submit this form.
        <br /> <br />
        <span className="underline">
          If your premium is paid by a third party who is not a family member,
          you are not eligible for LIPP.
        </span>
        <br /> <br />
        Information you add to apply online is only stored here temporarily.
        After seven days, if you have not completed and submitted your
        application, you will need to start over. Once submitted, your
        application is sent to 90 Degree Benefits. For assistance, call
        1-866-306-1882 or email{" "}
        <a href="mailto:NMMIP_Eligibility@90degreebenefits.com">
          NMMIP_Eligibility@90degreebenefits.com
        </a>
        .
      </p>
      <div className="flex flex-wrap gap-[1rem]">
        <Button
          type="button"
          href={
            settings?.lipp_qualification_guidelines ||
            "https://nmmip.org/eligibility-and-coverage/low-income-premium-program-lipp/"
          }
        >
          View LIPP Qualification Guidelines
        </Button>
        <Button
          type="button"
          href={settings?.lipp_rate_tables || "https://nmmip.org/rate-tables/"}
        >
          View LIPP Rate Tables
        </Button>
      </div>
    </>
  );
};

export default Intro;
