import React, { useState, useEffect } from "react";
import axios from "axios";
import Button, { ColorStyle } from "../Button/Button";
import styled from "styled-components";

const AdminDashboardContainer = styled.div`
	.field-row {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 5px;
	}

	.option-container {
		display: flex;
		flex-direction: column;
		gap: 16px;
		margin-bottom: 1.5rem;
	}
`;

const AdminDashboard: React.FC = () => {
	const [settings, setSettings] = useState<any | null>(null);
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);

	useEffect(() => {
		const fetchSettings = async () => {
			try {
				const response = await axios.get("/api/twilio/admin-settings");
				setSettings(response.data);
			} catch (error) {
				console.error("Error fetching settings:", error);
			} finally {
				setLoading(false);
			}
		};
		fetchSettings();
	}, []);

	const handleSave = async () => {
		setSaving(true);
		try {
			const token = localStorage.getItem("adminToken");
			if (!token) {
				alert("Unauthorized. Please log in again.");
				return;
			}

			await axios.post(
				"/api/twilio/admin-settings-update",
				{ ...settings },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			alert("Settings updated successfully!");
		} catch (error) {
			console.error("Error saving settings:", error);
			alert("Failed to save settings. Please try again.");
		} finally {
			setSaving(false);
		}
	};

	const updateDropdown = (
		fieldName: string,
		index: number,
		key: string,
		value: string | number
	) => {
		const updatedOptions = [...settings[fieldName]];
		updatedOptions[index][key] = value;
		setSettings({ ...settings, [fieldName]: updatedOptions });
	};

	const removeDropdownOption = (fieldName: string, index: number) => {
		if (settings[fieldName].length === 1) {
			alert("You must have at least one option.");
			return;
		}
		const updatedOptions = settings[fieldName].filter(
			(_: any, i: number) => i !== index
		);
		setSettings({ ...settings, [fieldName]: updatedOptions });
	};

	const addDropdownOption = (fieldName: string, defaultOption: any) => {
		const updatedOptions = [...settings[fieldName], defaultOption];
		setSettings({ ...settings, [fieldName]: updatedOptions });
	};

	if (loading) return <p>Loading...</p>;
	if (!settings) return <p>Error loading settings</p>;

	return (
		<AdminDashboardContainer className="admin-dashboard">
			<h1 className="text-2xl font-bold mb-6 text-center">Admin Dashboard</h1>

			{/* Dropdown Editors */}
			<h2 className="text-xl font-semibold mb-4">Edit Dropdown Options</h2>

			<label className="block font-medium mb-2">County Options:</label>
			{/* County Options */}
			<div className="option-container">
				{settings.county_options.map((option: any, index: number) => (
					<div key={index} className="field-row">
						<textarea
							value={option.label}
							onChange={(e) =>
								updateDropdown("county_options", index, "label", e.target.value)
							}
							className="border p-2 flex-1  min-w-[250px]"
						/>
						<Button
							type="button"
							onClick={() => removeDropdownOption("county_options", index)}
						>
							Remove
						</Button>
					</div>
				))}
				<Button
					type="button"
					onClick={() =>
						addDropdownOption("county_options", { label: "New County" })
					}
					buttonstyle={ColorStyle.Secondary}
				>
					Add Option
				</Button>
			</div>

			{/* Poverty Guideline Options */}
			<div className="option-container">
				<label className="block font-medium mb-2">
					Poverty Guideline Options:
				</label>
				{settings.poverty_guideline_options.map(
					(option: any, index: number) => (
						<div key={index} className="field-row">
							<input
								type="text"
								value={option.threshold}
								placeholder="Threshold"
								onChange={(e) =>
									updateDropdown(
										"poverty_guideline_options",
										index,
										"threshold",
										e.target.value
									)
								}
								className="border p-2 flex-1"
							/>
							<input
								type="number"
								value={option.reduction}
								placeholder="Reduction (%)"
								onChange={(e) =>
									updateDropdown(
										"poverty_guideline_options",
										index,
										"reduction",
										Number(e.target.value)
									)
								}
								className="border p-2 w-20"
							/>
							<Button
								type="button"
								onClick={() =>
									removeDropdownOption("poverty_guideline_options", index)
								}
							>
								Remove
							</Button>
						</div>
					)
				)}
				<Button
					type="button"
					onClick={() =>
						addDropdownOption("poverty_guideline_options", {
							threshold: "",
							reduction: 0,
						})
					}
					buttonstyle={ColorStyle.Secondary}
				>
					Add Option
				</Button>
			</div>

			{/* Household Size Options */}
			<div className="option-container">
				<label className="block font-medium mb-2">
					Household Size Options:
				</label>
				{settings.household_size_options.map(
					(option: { label: string; value: number }, index: number) => (
						<div key={index} className="field-row">
							{/* Input for Label */}
							<input
								type="text"
								value={option.label}
								placeholder="Label"
								onChange={(e) =>
									updateDropdown(
										"household_size_options",
										index,
										"label",
										e.target.value
									)
								}
								className="border p-2 flex-1"
							/>

							{/* Input for Value */}
							<input
								type="number"
								value={option.value}
								placeholder="Value"
								onChange={(e) =>
									updateDropdown(
										"household_size_options",
										index,
										"value",
										Number(e.target.value)
									)
								}
								className="border p-2 w-20"
							/>

							{/* Remove Option Button */}
							<Button
								type="button"
								onClick={() =>
									removeDropdownOption("household_size_options", index)
								}
							>
								Remove
							</Button>
						</div>
					)
				)}

				{/* Add New Option Button */}
				<Button
					type="button"
					onClick={() =>
						addDropdownOption("household_size_options", {
							label: "New Label",
							value: 0,
						})
					}
					buttonstyle={ColorStyle.Secondary}
				>
					Add Option
				</Button>
			</div>

			{/* Deductible Amounts */}
			<div className="option-container">
				<label className="block font-medium mb-2">Deductible Amounts:</label>
				{settings.deductible_amounts.map(
					(option: { label: string }, index: number) => (
						<div key={index} className="field-row">
							{/* Input for Label */}
							<input
								type="text"
								value={option.label}
								placeholder="Label"
								onChange={(e) =>
									updateDropdown(
										"deductible_amounts",
										index,
										"label",
										e.target.value
									)
								}
								className="border p-2 flex-1"
							/>

							{/* Remove Option Button */}
							<Button
								type="button"
								onClick={() =>
									removeDropdownOption("deductible_amounts", index)
								}
							>
								Remove
							</Button>
						</div>
					)
				)}

				{/* Add New Option Button */}
				<Button
					type="button"
					onClick={() =>
						addDropdownOption("deductible_amounts", { label: "New Deductible" })
					}
					buttonstyle={ColorStyle.Secondary}
				>
					Add Option
				</Button>
			</div>

			{/* Text Inputs */}
			<h2 className="text-xl font-semibold mb-4">Edit Text Fields</h2>
			<div className="mb-6">
				<label className="block font-medium mb-2">
					Deductible Description:
				</label>
				<textarea
					value={settings.deductible_description}
					onChange={(e) =>
						setSettings({
							...settings,
							deductible_description: e.target.value,
						})
					}
					className="border p-2 w-full"
				/>
			</div>
			<div className="mb-6">
				<label className="block font-medium mb-2">
					Premium Payment Address:
				</label>
				<textarea
					value={settings.premium_payment_address}
					onChange={(e) =>
						setSettings({
							...settings,
							premium_payment_address: e.target.value,
						})
					}
					className="border p-2 w-full"
				/>
			</div>
			<div className="mb-6">
				<label className="block font-medium mb-2">
					Benefits Information Link:
				</label>
				<textarea
					value={settings.benefits_information_link}
					onChange={(e) =>
						setSettings({
							...settings,
							benefits_information_link: e.target.value,
						})
					}
					className="border p-2 w-full"
				/>
			</div>
			<div className="mb-6">
				<label className="block font-medium mb-2">Premium Rates Link:</label>
				<textarea
					value={settings.premium_rates_link}
					onChange={(e) =>
						setSettings({
							...settings,
							premium_rates_link: e.target.value,
						})
					}
					className="border p-2 w-full"
				/>
			</div>
			<div className="mb-6">
				<label className="block font-medium mb-2">Broker Link:</label>
				<textarea
					value={settings.broker_link}
					onChange={(e) =>
						setSettings({
							...settings,
							broker_link: e.target.value,
						})
					}
					className="border p-2 w-full"
				/>
			</div>
			<div className="mb-6">
				<label className="block font-medium mb-2">
					Qualifying Rate Chart Link:
				</label>
				<textarea
					value={settings.qualifying_rate_chart_link}
					onChange={(e) =>
						setSettings({
							...settings,
							qualifying_rate_chart_link: e.target.value,
						})
					}
					className="border p-2 w-full"
				/>
			</div>
			<div className="mb-6">
				<label className="block font-medium mb-2">
					LIPP Qualification Guidelines:
				</label>
				<textarea
					value={settings.lipp_qualification_guidelines}
					onChange={(e) =>
						setSettings({
							...settings,
							lipp_qualification_guidelines: e.target.value,
						})
					}
					className="border p-2 w-full"
				/>
			</div>
			<div className="mb-6">
				<label className="block font-medium mb-2">LIPP Rate Tables:</label>
				<textarea
					value={settings.lipp_rate_tables}
					onChange={(e) =>
						setSettings({
							...settings,
							lipp_rate_tables: e.target.value,
						})
					}
					className="border p-2 w-full"
				/>
			</div>
			<div className="mb-6">
				<label className="block font-medium mb-2">
					Supporting Documentation Address:
				</label>
				<textarea
					value={settings.supporting_documentation_address}
					onChange={(e) =>
						setSettings({
							...settings,
							supporting_documentation_address: e.target.value,
						})
					}
					className="border p-2 w-full"
				/>
			</div>
			<div className="mb-6">
				<label className="block font-medium mb-2">Affidavit Year 1:</label>
				<textarea
					value={settings.affidavit_year_1}
					onChange={(e) =>
						setSettings({
							...settings,
							affidavit_year_1: e.target.value,
						})
					}
					className="border p-2 w-full"
				/>
			</div>
			<div className="mb-6">
				<label className="block font-medium mb-2">Affidavit Year 2:</label>
				<textarea
					value={settings.affidavit_year_2}
					onChange={(e) =>
						setSettings({
							...settings,
							affidavit_year_2: e.target.value,
						})
					}
					className="border p-2 w-full"
				/>
			</div>

			{/* Save Button */}
			<div className="flex justify-end">
				<Button
					type="button"
					onClick={handleSave}
					disabled={saving}
					className="mt-4"
					buttonstyle={ColorStyle.Secondary}
				>
					{saving ? "Saving..." : "Save Changes"}
				</Button>
			</div>
		</AdminDashboardContainer>
	);
};

export default AdminDashboard;
