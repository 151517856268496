import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import StyledList from "../../../components/Lists/Lists";
import { useAdminSettings } from "../../../components/AdminSettingsProvider/AdminSettingsProvider";

const requiredWarning = "This field is required.";

export const schema = {
  values: {
    affirmation: {
      applicantSignature: "",
      todaysDate: "",
      legalGuardian: "",
      legalGuardianRelationship: "",
    },
  },
  validation: Yup.object().shape({
    affirmation: Yup.object().shape({
      applicantSignature: Yup.string().required(requiredWarning),
      todaysDate: Yup.string()
        .required(requiredWarning)
        .test({
          name: "today",
          message: "Must select today's date",
          test(value, ctx) {
            if (value) {
              return new Date(value).getDate() === new Date().getDate();
            } else return true;
          },
        }),
    }),
  }),
};

const Affirmation = () => {
  const { values } = useFormikContext<any>();
  const { settings, loading } = useAdminSettings();

  return (
    <>
      <h2>
        I understand the first month's premium must be included with the
        application.
      </h2>

      <p>
        I certify that the foregoing statements are true and accurate to the
        best of my knowledge. I understand that no coverage will be effective
        until the full initial premium is paid and this application has been
        approved by the NMMIP Administrator. I understand that if I obtain or
        become eligible for health coverage, I will notify the NMMIP
        Administrator of the other coverage.
      </p>

      <Signature
        name="affirmation.applicantSignature"
        label="Signature of Applicant"
        signatureType="AffirmationApplication2"
        required
      />

      <DateField
        className="max-w-[300px]"
        name="affirmation.todaysDate"
        label="Today's Date"
        placeholder="Select Today's Date"
        required
      />

      <p>
        A parent/legal guardian/personal representative must initial if the
        applicant is under 18 years of age or legally incompetent.
      </p>

      <FormGrid>
        <TextField
          type="text"
          name="affirmation.legalGuardian"
          label="Name of parent/legal guardian/personal representative"
          autoComplete="off"
          mobileExtraPadding
        />
        <TextField
          type="text"
          name="affirmation.legalGuardianRelationship"
          label="Relationship to Applicant"
          autoComplete="off"
        />
      </FormGrid>

      {values["paymentType"] === "Money Order or Check" && (
        <>
          <p className="leading-[1.5rem]">
            <strong>
              If paying first premium by check or money order, you must MAIL the
              application and all attachments WITH the payment.
            </strong>
            <br />
            Please include the applicant's first and last name, along with the
            email address or phone number used to sign into this online
            application.
          </p>

          <p className="leading-[1.5rem]">
            <strong>Make money order or check payable to:</strong>
            <br />
            New Mexico Medical Insurance Pool (NMMIP)
          </p>

          <p className="leading-[1.5rem]">
            <strong>Mail complete application and premium check to:</strong>{" "}
            <br />
            <span
              dangerouslySetInnerHTML={{
                __html:
                  settings?.premium_payment_address ||
                  `New Mexico Medical Insurance Pool (NMMIP) <br />
									P.O. Box 27745 <br />
									Albuquerque, NM 87125-7745`,
              }}
            ></span>
          </p>
        </>
      )}
    </>
  );
};

export default Affirmation;
