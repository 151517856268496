import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import { GlobalStyles } from "./globalStyles";
import HealthCareForm from "./views/HealthCareCoverageForm/FormSchema/HealthcareForm";
import Header from "./components/Header/Header";
import VerificationForm from "./components/VerficiationForm/VerificationForm";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import FormContainer from "./components/FormContainer/FormContainer";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import LippForm from "./views/LIPP/FormSchema/LIPPForm";
import MedicareForm from "./views/Medicare/FormSchema/MedicareForm";
import NewbornForm from "./views/Newborn/FormSchema/NewbornForm";
import GoogleTranslateWidget from "./components/GoogleTranslate/GoogleTranslateWidget";
import { FormProvider } from "./components/FormContext/FormContext";
import Home from "./views/Home/Home";
import PageNotFound from "./views/PageNotFound/PageNotFound";
import AdminVerificationForm from "./components/VerficiationForm/AdminVerificationForm";

const Layout = (props: { showHeader?: boolean }) => (
  <>
    {props.showHeader && <Header />}
    <Outlet />
  </>
);

function App() {
  return (
    <FormProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <FormContainer>
          <AuthProvider>
            <Router>
              <Routes>
                <Route path="/" element={<VerificationForm />} />
                <Route path="/login" element={<VerificationForm />} />
                {/* Admin Route */}
                <Route path="/admin" element={<AdminVerificationForm />} />
                <Route
                  path="/home"
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />
                <Route element={<Layout showHeader={true} />}>
                  <Route
                    path="/healthcare-coverage-form"
                    element={
                      <ProtectedRoute>
                        <HealthCareForm />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/lipp-form/:medicare?"
                    element={
                      <ProtectedRoute>
                        <LippForm />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/medicare-form"
                    element={
                      <ProtectedRoute>
                        <MedicareForm />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/newborn-form"
                    element={
                      <ProtectedRoute>
                        <NewbornForm />
                      </ProtectedRoute>
                    }
                  />
                </Route>
                <Route path="*" element={<PageNotFound />} />
              </Routes>
              {/* <Routes>
								<Route element={<Layout showHeader={true} />}>
									<Route path="ada-compliance">
										<Route
											path="healthcare-coverage-form"
											element={<HealthCareFormCopy />}
										/>
										<Route
											path="lipp-form/:medicare?"
											element={<LippFormCopy />}
										/>
										<Route
											path="medicare-form"
											element={<MedicareFormCopy />}
										/>
										<Route path="newborn-form" element={<NewbornFormCopy />} />
									</Route>
								</Route>
							</Routes> */}
              <Routes></Routes>
            </Router>
          </AuthProvider>
        </FormContainer>
      </ThemeProvider>
    </FormProvider>
  );
}

export default App;
