import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload, {
  UploadedFileList,
} from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import {
  DeductibleAmounts,
  MonthsOfYear,
  USStates,
  Years,
} from "../FormSchema/formInitialValues";
import { useEffect } from "react";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import styled, { css } from "styled-components";
import {
  countyOptions,
  householdSizeOptions,
  incomeThresholdMap2024,
  incomeThresholdMap2025,
  povertyGuidelineOptions,
} from "../../LIPP/FormSchema/formInitialValues";
import StyledLegend, {
  BlueLegendHeading,
} from "../../../components/Legend/Legend";
import {
  isDateWithin95Days,
  isDateWithin95DaysAfter,
} from "../../../utils/95days";
import { useFormContext } from "../../../components/FormContext/FormContext";
import { useAdminSettings } from "../../../components/AdminSettingsProvider/AdminSettingsProvider";

const requiredWarning = "This field is required.";

export const schema = {
  values: {
    applyingForLipp: "",
    coverageStartMonth: "",
    coverageStartYear: "",
    county: "",
    householdSize: "",
    householdAnnualIncome: "", // only shows upper threshold of annual income
    householdAnnualIncomeString: "", // the string value of lower/upper threshold of annual income
    deductible: "",
  },
  validation: Yup.object().shape({
    applyingForLipp: Yup.string().required(requiredWarning),
    coverageStartMonth: Yup.string().required(requiredWarning),
    coverageStartYear: Yup.string().required(requiredWarning),
    county: Yup.string().test({
      name: "countyRequired",
      message: requiredWarning,
      test(value, ctx) {
        const { applyingForLipp } = ctx.parent;
        return applyingForLipp === "Yes" ? (value ? true : false) : true;
      },
    }),
    householdSize: Yup.string().test({
      name: "householdSizeRequired",
      message: requiredWarning,
      test(value, ctx) {
        const { applyingForLipp } = ctx.parent;
        return applyingForLipp === "Yes" ? (value ? true : false) : true;
      },
    }),
    householdAnnualIncome: Yup.string().test({
      name: "householdAnnualIncomeRequired",
      message: requiredWarning,
      test(value, ctx) {
        const { applyingForLipp } = ctx.parent;
        return applyingForLipp === "Yes" ? (value ? true : false) : true;
      },
    }),
    deductible: Yup.string().required(requiredWarning),
  }),
};

const CoverageDetails = () => {
  const { values, setFieldValue } = useFormikContext<any>();
  const coverageStartMonth = values["coverageStartMonth"];
  const coverageStartYear = values["coverageStartYear"];
  const applyingForLipp = values["applyingForLipp"];
  const { formId } = useFormContext(); // Get formId from context
  const { settings, loading } = useAdminSettings();
  const minimum18MonthsCoverage = values["minimum18MonthsCoverage"];
  const isGroupCoverage = values["isGroupCoverage"];
  const applyingWithin95Days = values["applyingWithin95Days"];
  const priorCoverageEndDate = values["priorCoverageEndDate"];
  const hipaaEligible =
    minimum18MonthsCoverage === "Yes" &&
    isGroupCoverage === "Yes" &&
    applyingWithin95Days === "Yes";

  // allow users to get previous year rates if their new coverage start date is within 95 days of prior coverage end date
  const isPreviousYear =
    new Date(priorCoverageEndDate).getFullYear() < new Date().getFullYear();
  const retroactiveStart =
    priorCoverageEndDate &&
    coverageStartYear &&
    coverageStartMonth &&
    isDateWithin95DaysAfter(
      `${coverageStartMonth} 01, ${coverageStartYear}`,
      priorCoverageEndDate
    ) &&
    isPreviousYear;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [incomeThreshold, setIncomeThreshold] = useState<number[] | null>(null);

  const householdSize = values["householdSize"];

  const householdAnnualIncome = values["householdAnnualIncome"];

  const maxIncomeIndex = incomeThreshold?.indexOf(
    Number(householdAnnualIncome)
  );
  const povertyGuideline =
    maxIncomeIndex !== undefined &&
    maxIncomeIndex !== -1 &&
    povertyGuidelineOptions[maxIncomeIndex];

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (coverageStartMonth && coverageStartYear) {
      const selectedDate = new Date(
        `${coverageStartMonth} 1, ${coverageStartYear}`
      );
      const selectedMonth = selectedDate.getMonth();
      // const nextMonth = (currentMonth + 1) % 12;
      // const nextTwoMonths = ((currentMonth + 1) % 12) + 1;

      const d1 = new Date();
      d1.setMonth(currentMonth + 1);
      const nextMonth = d1.getMonth();
      const isNextMonth =
        selectedMonth === nextMonth &&
        // in nov , user must select THIS year
        (currentMonth === 10
          ? Number(coverageStartYear) === currentYear
          : // in dec, user must select NEXT year
          currentMonth === 11
          ? Number(coverageStartYear) > currentYear
          : true);

      const d2 = new Date();
      d2.setMonth(currentMonth + 2);
      const nextTwoMonths = d2.getMonth();
      const isNextTwoMonths =
        selectedMonth === nextTwoMonths &&
        // in nov or dec, user must select NEXT year
        (currentMonth >= 10 ? Number(coverageStartYear) > currentYear : true);

      // const priorCoverageEndMonth =
      // 	priorCoverageEndDate && new Date(priorCoverageEndDate).getMonth();
      // const coverageNextMonth = (priorCoverageEndMonth + 1) % 12;

      // show modal if selected month is NOT next month NOR next 2 months
      if (
        hipaaEligible && retroactiveStart
          ? false
          : !isNextMonth && !isNextTwoMonths
      ) {
        setIsModalOpen(true);
      }
    } else {
      setIsModalOpen(false);
    }
  }, [coverageStartMonth, coverageStartYear]);

  useEffect(() => {
    if (coverageStartMonth && coverageStartYear && householdSize) {
      if (Number(coverageStartYear) === 2024) {
        setIncomeThreshold(incomeThresholdMap2024(Number(householdSize)));
      } else {
        setIncomeThreshold(incomeThresholdMap2025(Number(householdSize)));
      }
    } else {
      setIncomeThreshold(null);
    }
  }, [coverageStartMonth, coverageStartYear, householdSize]);

  useEffect(() => {
    if (
      householdAnnualIncome &&
      incomeThreshold &&
      incomeThreshold.length > 0 &&
      maxIncomeIndex !== undefined &&
      maxIncomeIndex !== -1
    ) {
      const lowerThreshold =
        maxIncomeIndex === 0 ? "$0" : `$${incomeThreshold[maxIncomeIndex - 1]}`;

      setFieldValue(
        "householdAnnualIncomeString",
        `${lowerThreshold} - $${Number(householdAnnualIncome)}`
      );
    } else {
      setFieldValue("householdAnnualIncomeString", "");
    }
  }, [householdAnnualIncome]);

  return (
    <>
      <p>
        Coverage is effective{" "}
        <span className="underline">on the first day of the month</span>{" "}
        following receipt of completed application and first premium payment.
        <br /> <br />
        To determine rates and the amount you would pay for insurance through
        NMMIP, view the benefits and premium rates information. There, you can
        find detailed information on monthly premiums, qualifying rates, and
        discounts available through the Low Income Premium Program (LIPP). These
        resources will help you understand the cost structure and select the
        appropriate plan based on your eligibility and financial situation.
      </p>

      <div className="flex flex-wrap gap-[1rem]">
        <Button
          type="button"
          href={
            settings?.benefits_information_link ||
            "https://nmmip.org/members/summary-of-benefits/"
          }
        >
          View Benefits Information
        </Button>
        <Button
          type="button"
          href={
            settings?.premium_rates_link || "https://nmmip.org/rate-tables/"
          }
        >
          View Premium Rates Information
        </Button>
        <Button
          type="button"
          href={
            settings?.lipp_qualification_guidelines ||
            "https://nmmip.org/eligibility-and-coverage/low-income-premium-program-lipp/"
          }
        >
          View LIPP Qualification Guidelines
        </Button>
        <Button
          type="button"
          href={settings?.lipp_rate_tables || "https://nmmip.org/rate-tables/"}
        >
          View LIPP Rate Tables
        </Button>
      </div>
      <FormGrid className="flex-col">
        <Radio
          className="!max-w-[700px]"
          name="applyingForLipp"
          label="Are you applying for the Low Income Premium Program (LIPP)?"
          options={[{ label: "Yes" }, { label: "No" }]}
          required
        />

        {applyingForLipp === "Yes" && (
          <BlueLegendHeading className="!max-w-full !flex-none !mt-0 !mb-0">
            You've indicated that you'll be applying for LIPP. Once you complete
            this application for coverage, you'll be directed to the LIPP
            application to provide further information including income details.
          </BlueLegendHeading>
        )}

        <StyledLegend className="!max-w-[700px] !mb-0">
          <BlueLegendHeading>
            Select when you would like your NMMIP coverage to begin:
          </BlueLegendHeading>
          <div className="flex flex-wrap gap-[1rem]">
            <Dropdown
              className="!basis-[300px]"
              name="coverageStartMonth"
              label="Select a Month"
              options={MonthsOfYear}
              required
            />
            <Dropdown
              className="!basis-[300px]"
              name="coverageStartYear"
              label="Select a Year"
              options={Years(
                // show previous year if today is within 95 days of the last day in the previous year
                isDateWithin95DaysAfter(
                  new Date().toDateString(),
                  `12/31/${new Date().getFullYear() - 1}`
                ),
                // show next year if it's nov or dec
                currentMonth >= 10
              )}
              required
            />
          </div>
        </StyledLegend>
        {applyingForLipp === "Yes" && (
          <StyledLegend className="max-w-full !mb-0">
            <BlueLegendHeading>
              Select household size and income
            </BlueLegendHeading>
            <div className="flex flex-wrap gap-[1rem]">
              <Dropdown
                className="flex-1"
                name="county"
                label="Select County"
                options={settings?.county_options || countyOptions}
                required
              />
              <Dropdown
                className="flex-1"
                name="householdSize"
                label="Select Household Size"
                options={
                  settings?.household_size_options || householdSizeOptions
                }
                required
              />

              <Dropdown
                className="flex-1"
                name="householdAnnualIncome"
                label="Select Household Yearly Income"
                options={
                  householdSize && incomeThreshold
                    ? incomeThreshold.map((amount, i) => {
                        if (i === 0) {
                          return {
                            label: `$0 - ${formatter.format(amount)}`,
                            value: amount,
                          };
                        } else {
                          const prevThreshold = incomeThreshold[i - 1];
                          return {
                            label: `${formatter.format(
                              prevThreshold + 1
                            )} - ${formatter.format(amount)}`,
                            value: amount,
                          };
                        }
                      })
                    : []
                }
                required
              />
            </div>
          </StyledLegend>
        )}

        <StyledLegend
          className={`!mb-0 ${applyingForLipp === "Yes" ? "!max-w-full " : ""}`}
        >
          <BlueLegendHeading>
            Select a deductible amount for your coverage:
          </BlueLegendHeading>

          {applyingForLipp === "Yes" && povertyGuideline ? (
            <p className="mb-[1rem] !font-normal">
              {settings?.deductible_description
                ? settings.deductible_description
                    .replace(
                      "${povertyGuideline.threshold}",
                      povertyGuideline.threshold
                    )
                    .replace(
                      "${povertyGuideline.reduction}",
                      String(povertyGuideline.reduction)
                    )
                : ""}
            </p>
          ) : (
            <></>
          )}
          <Dropdown
            className="!max-w-[700px]"
            name="deductible"
            label="Select an Amount"
            options={settings?.deductible_amounts || DeductibleAmounts}
            required
          />
        </StyledLegend>
      </FormGrid>

      <Modal
        modalLabel="NMMIP Eligibility Criteria"
        open={isModalOpen}
        setOpen={setIsModalOpen}
      >
        <NoticeBanner bannerstyle={ColorStyle.Primary}>
          You can only select the next month or the month thereafter for NMMIP
          coverage start date.
        </NoticeBanner>
        <NoticeBanner bannerstyle={ColorStyle.Secondary}>
          Unless HIPAA eligible, your response may indicate that you are not
          eligible for coverage. However, you can continue to submit an
          application for review.
        </NoticeBanner>
        <Button
          className="mx-auto"
          type="button"
          onClick={() => setIsModalOpen(false)}
        >
          Continue Application <FaArrowRight />
        </Button>
      </Modal>

      {hipaaEligible && retroactiveStart && (
        <>
          <Modal
            modalLabel="Certificate of Creditable Coverage"
            buttonLabel="Upload Documentation"
          >
            <NoticeBanner bannerstyle={ColorStyle.Primary}>
              HIPAA eligible applicants can retroactively receive rates from the
              previous year for 95 days after their prior coverage has ended.
            </NoticeBanner>
            <NoticeBanner bannerstyle={ColorStyle.Primary}>
              You have selected a retroactive date. Please provide a certificate
              of creditable coverage.
            </NoticeBanner>

            <NoticeBanner bannerstyle={ColorStyle.Secondary}>
              If you don't have the documentation electronically, please mail it
              to NMMIP upon completing this application.
            </NoticeBanner>
            <FileUpload
              name="documentation.creditableCoverage"
              label="Upload Documentation"
              documentationType="creditableCoverage"
            />
          </Modal>
          <div className="max-w-[500px]">
            <UploadedFileList
              formId={formId}
              fieldName={"documentation.creditableCoverage"}
              documentationType="creditableCoverage"
            />
          </div>
        </>
      )}
    </>
  );
};

export default CoverageDetails;
